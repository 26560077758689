/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.mega-menu {
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}